<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">{{ $store.getters['languageMixin/getStringFromLanguage']('Training Sessions List') }}
            <span style="color:#938f8f;font-size: 15px;margin-left: 10px">{{trainingName}}</span></h4>

          <div class="d-flex justify-content-start">
            <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['training-sessions/create'])"
                         :to="'/training-sessions/create'"
                         class="btn btn-info btn-wd" wide>
              {{ $store.getters['languageMixin/getStringFromLanguage']('Add New') }}
              <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
            </router-link>
          </div>
        </div>

        <!--    filters    -->
        <div class="row col-12 mb-2">
          <div class="col-md-4 col-sm-4">
            <fg-select
              size="large"
              filterable
              clearable
              :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Select Training')"
              :input-classes="'select-default'"
              :list="filterBuilders.trainingList"
              :listItemLabel="'title'"
              :listItemValue="'id'"
              v-model="filters.training_id">
            </fg-select>
          </div>

          <div class="col-md-3 col-sm-4">
            <l-button class="mr-lg-1" @click="applyFilter()" type="info">{{ $store.getters['languageMixin/getStringFromLanguage']('Filter Result') }}</l-button>
          </div>
        </div>
        <!--  end  filters    -->

        <div>
          <general-data-table
            ref="table"
            :key="componentKey"
            :api-url="'training-sessions/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">

              <template slot-scope="scope">

                <span class="training_title" v-if="column.value === 'training.title'" v-html="scope.row['training'].training_title"></span>
                <span v-else v-html="scope.row[column.value]"></span>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="200"
              align="center"
              fixed="right"
              :label="$store.getters['languageMixin/getStringFromLanguage']('Actions')">
              <template slot-scope="scope">

                <template>
                  <router-link
                               v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Session Registrations')"
                               class="btn-success btn-simple btn-link"
                               :to="'/training-sessions/registrations/session/'+scope.row.id">
                    <i class="fa fa-eye"></i>
                  </router-link>
                  <router-link
                    v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Waiting List')"
                    class="btn-info btn-simple btn-link"
                    :to="'/waiting-lists/list/session/'+scope.row.id">
                    <i class="fa fa-hourglass-half"></i>
                  </router-link>
                  <router-link
                    v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Email Archive List')"
                    class="btn-default btn-simple btn-link"
                    :to="'/email-archives/list/session/'+scope.row.id">
                    <i class="fa fa-envelope"></i>
                  </router-link>
                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['training-sessions/update'])"
                               v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Edit')"
                               class="btn-warning btn-simple btn-link"
                               :to="'/training-sessions/edit/'+scope.row.id">
                    <i class="fa fa-edit"></i>
                  </router-link>

                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['training-sessions/delete'])"
                     v-tooltip.top-center="$store.getters['languageMixin/getStringFromLanguage']('Delete')"
                     class="btn-danger btn-simple btn-link"
                     @click="openDeleteModal(scope.row.id)">
                    <i class="fa fa-times"></i></a>
                </template>


              </template>
            </el-table-column>
          </general-data-table>
        </div>
      </card>
      <delete-modal :visible="deleteModalVisibility"
                    :message="$store.getters['languageMixin/getStringFromLanguage']('Are you sure you want to delete this session?')"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
    </div>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";


export default {
  components: {
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    FgSelect,
  },

  data() {

    return {
      tableColumns: [
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Training'), value: 'training.title', minWidth: '200', align: 'center'},
        // {label: this.$store.getters['languageMixin/getStringFromLanguage']('URL'), value: 'url', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Start / End Dates'), value: 'days', minWidth: '200', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Registrations / Places'), value: 'total_places_registrations', minWidth: '90', align: 'center'},
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Last update / Created at'), value: 'creation_dates', minWidth: '120', align: 'center', class: 'creation_dates'},
      ],

      filterBuilders: {
        trainingList: []
      },

      filters: {
        training_id: null,
        type: 'normal',
        sessionType: null
      },
      deleteModalVisibility: false,
      toDeleteId: undefined,
      componentKey: 0,
      trainingSessionUrl: 'training-sessions/index',
      trainingName: '',
    }

  },
  watch:{
    $route (to, from){
      this.filters.sessionType = this.$route.params['sessionType'];
      this.filters.training_id = null;
      this.applyFilter();
    },
  },
  async mounted() {
    let data = {};
    let response = await this.axios.post("training-sessions/builder", data);
    this.filterBuilders.trainingList = response.data.trainings;
    this.filters.sessionType = this.$route.params['sessionType'];
    if(this.$route.params['training_id']){
      this.filters.training_id = parseInt(this.$route.params['training_id']);

    }
    this.applyFilter();
  },
  methods: {
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    async confirmDeleteModal() {
      try {
        let data = {
            'id': this.toDeleteId,
        }
        await this.axios.delete("training-sessions/delete", {headers: {}, data});
        this.$refs.table.getData("updateData");
        this.$notify({
          message: this.$store.getters['languageMixin/getStringFromLanguage']("Training Sessions deleted successfully"),
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },

    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.filters.training_id || this.filters.sessionType) {
        let training = this.filterBuilders.trainingList.filter(item => item.id === this.filters.training_id);
        this.trainingName = training[0]?.training_title;
        this.$refs.table.createDragAndDropSorting();
      } else {
        this.$refs.table.destroyDragAndDropSorting();
      }
      // this.componentKey++;
      this.$refs.table.getData("updateData");
    },
  },
}
</script>
<style>
</style>
