<template>

  <div class="row" ref="groupForm">
<!--    <div class="alert alert-danger col-12" v-if="formData && formData.is_session_started">{{ $store.getters['languageMixin/getStringFromLanguage']('You cannot edit this session because it has already started.') }}</div>-->
    <div class="col-xs-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="fr.training_id"
                  rules="required"
                  name="The Training"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="training_id"
                    size="large"
                    filterable
                    clearable
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Training')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Training')"
                    :list="builderData.trainingList"
                    :listItemLabel="'title'"
                    :listItemValue="'id'"
                    @change="changeSessionCost"
                    v-model="formData.training_id">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label class="card-label">{{ $store.getters['languageMixin/getStringFromLanguage']('Start Date') }}</label>
                <ValidationProvider
                  vid="fr.start_date"
                  rules="required"
                  name="The Start Date"
                  v-slot="{ passed, failed,errors }">
                    <fg-input v-model="formData.start_date"
                              value-format="YYYY-MM-DD HH:MM:SS"
                              type="datetime-local"
                              :disabled="formData && formData.is_session_started"
                              @change="generateSomeFields"
                              :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Chose panel publish date')"
                              :error="failed ? errors[0]: null"
                    >
                    </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <label class="card-label">{{ $store.getters['languageMixin/getStringFromLanguage']('End Date') }}</label>
                <fg-input v-model="formData.end_date"
                          value-format="YYYY-MM-DD HH:MM:SS"
                          type="datetime-local"
                          :disabled="formData && formData.is_session_started"
                          :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Chose panel expire date')"
                >

                </fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="url"
                  rules="required"
                  name="The URL"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            :label="$store.getters['languageMixin/getStringFromLanguage']('URL')"
                            name="url"
                            fou
                            v-model="formData.url">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <ValidationProvider
                  vid="fr.cost"
                  rules="required"
                  name="The Cost"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="number"
                            :error="failed ? errors[0]: null"
                            :label="$store.getters['languageMixin/getStringFromLanguage']('Cost')"
                            name="cost"
                            fou
                            v-model="formData.cost">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  vid="fr.places"
                  rules="required"
                  name="The Places"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="number"
                            :error="failed ? errors[0]: null"
                            :label="$store.getters['languageMixin/getStringFromLanguage']('places (enter 0 for unlimited number of places)')"
                            name="places"
                            fou
                            v-model="formData.places">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="notes"
                  rules=""
                  name="The Additional Information"
                  v-slot="{ passed, failed,errors }">
                  <fg-text type="text"
                           :error="failed ? errors[0]: null"
                           :label="$store.getters['languageMixin/getStringFromLanguage']('additional information')"
                           name="additional_information"
                           fou
                           v-model="formData.additional_information">
                  </fg-text>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="fr.address"
                  rules="required"
                  name="The Address"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            :label="$store.getters['languageMixin/getStringFromLanguage']('address')"
                            name="address"
                            fou
                            v-model="formData.address">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <ValidationProvider
                  vid="fr.country"
                  rules=""
                  name="The Country"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="country"
                    size="large"
                    filterable
                    clearable
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('country')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('country')"
                    :list="builderData.countryList"
                    :listItemLabel="'name'"
                    :listItemValue="'name'"
                    v-model="formData.country">
                  </fg-select>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  vid="fr.city"
                  rules="required"
                  name="The City"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            :label="$store.getters['languageMixin/getStringFromLanguage']('City')"
                            name="city"
                            fou
                            v-model="formData.city">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="notes"
                  rules=""
                  name="The Notes"
                  v-slot="{ passed, failed,errors }">
                  <fg-text type="text"
                           :error="failed ? errors[0]: null"
                           :label="$store.getters['languageMixin/getStringFromLanguage']('Notes')"
                           name="notes"
                           fou
                           v-model="formData.notes">
                  </fg-text>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Training Program') }}</label>
                  <el-tooltip placement="right" v-if="false">
                    <div slot="content"></div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-pdf-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    :maxFileSize="10000000"
                    :isEditMode="editMode"
                    v-model="formData.pdf_programme"
                  >
                  </prime-pdf-uploader>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Training Booklet') }}</label>
                  <el-tooltip placement="right" v-if="false">
                    <div slot="content"></div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-pdf-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    :maxFileSize="10000000"
                    :isEditMode="editMode"
                    v-model="formData.pdf_livret"
                  >
                  </prime-pdf-uploader>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Educational materials') }}</label>
                  <el-tooltip placement="right" v-if="false">
                    <div slot="content"></div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-pdf-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="999"
                    :maxFileSize="10000000"
                    :isEditMode="editMode"
                    v-model="formData.educational_materials"
                  >
                  </prime-pdf-uploader>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Gallery') }}</label>
              <el-tooltip placement="right" v-if="false">
                <div slot="content"></div>
                <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
              </el-tooltip>
              <prime-uploader
                :preview-width="'200px'"
                :preview-height="'200px'"
                :elementNum="999"
                :maxFileSize="10000000"
                v-model="formData.gallery"
              >
              </prime-uploader>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Videos') }}</label>
                <div class="col-12">
                  <template class="row" v-for="(listItem,listItemIndex) in formData.videos">
                    <div class="row team_list_items">
                      <label>{{$store.getters['languageMixin/getStringFromLanguage']('Item') }} # {{ listItemIndex + 1 }}</label>
                      <div class="col-md-12">
                        <ValidationProvider
                          vid="fr.videolink"
                          rules=""
                          name="The Link"
                          v-slot="{ passed, failed,errors }">
                          <fg-input type="text"
                                    :key="'link_'+listItemIndex"
                                    :error="failed ? errors[0]: null"
                                    :label="$store.getters['languageMixin/getStringFromLanguage']('Link')+' '+(listItemIndex+1)"
                                    v-model="formData.videos[listItemIndex]">
                          </fg-input>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-sm-1 align-self-center mt-3">
                      <l-button
                        @click="removeItem(formData.videos, listItemIndex)"
                        type="danger" size="sm">
                        <i class="fa fa-trash"></i>
                      </l-button>
                    </div>
                  </template>
                  <l-button
                    class="mt-3"
                    type="success"
                    @click="addNewItem(formData.videos, '')"
                    size="sm">
                    <i class="fa fa-plus"></i>
                  </l-button>
                </div>
              </div>
            </div>
            <div class="form-group pt-4">
              <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Show Only in Cycle') }}</label>&nbsp;
              <l-switch v-model="formData.only_in_cycle">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>
            <div class="form-group pt-4">
              <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Hide The Cost') }}</label>&nbsp;
              <l-switch v-model="formData.hide_cost">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Submit') }}
            </l-button>
            <l-button @click="$router.push('/training-sessions/list')" type="danger" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Cancel') }}
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import PrimePdfUploader from "../../../components/PrimePdfUploader";
import {Option, Select, Tooltip} from "element-ui";
import Editor from '@tinymce/tinymce-vue'

extend("required", {
  message: "{_field_} is required"
});

export default {
  components:{
    PrimePdfUploader,
    PrimeUploader,
    FgSelect,
    LSwitch,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    'editor': Editor
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        training_id: "",
        start_date: "",
        end_date: "",
        cost: 0,
        places: '',
        additional_information: '',
        address: '',
        country: '',
        city: '',
        notes: '',
        pdf_programme: '',
        pdf_livret: '',
        educational_materials: [],
        gallery: [],
        videos: [],
        only_in_cycle: false,
        hide_cost: false,
      },
      builderData: {
        trainingList: [],
        countryList: [],
      },
    };
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.groupForm
    });

    let data = {}
    this.axios.post("training-sessions/builder", data).then((response) => {
      this.builderData.trainingList = response.data.trainings;
      this.generateCountries();

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Edit Training Session");
        this.getItem();
      } else {
        this.editMode = false;
        this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Add Training Session");
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getItem() {
      let data = {
        'id': this.id,
      };
      this.axios.post("training-sessions/get", data).then((response) => {
        this.formData = response.data;
        this.vueDate();
        // if (this.formData.is_session_started){
        //   this.submitting = true;
        // }
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: this.$store.getters['languageMixin/getStringFromLanguage']("Training Session Not Found"),
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      this.laravelDate();
      if (this.editMode === true) {
        request = this.axios.put("training-sessions/update/" + this.id, this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Training Session Updated Successfully");
      } else {
        request = this.axios.post("training-sessions/create", this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Training Session Added Successfully");
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/training-sessions/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          if (error.response.data.errors && error.response.data.errors['start_date']){
            this.$notify({
              message: error.response.data.errors['start_date'][0],
              timeout: 2000,
              type: 'danger'
            });
          }
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    changeSessionCost(){
     if (!this.editMode){
       let selectedItem = this.builderData.trainingList.find(t => t.id == this.formData.training_id);
       this.formData.cost = selectedItem.cost;
       this.generateSomeFields();
     }
    },
    generateCountries(){
      this.axios.get('https://restcountries.com/v2/all')
        .then(response => {
          this.builderData.countryList = response.data;
          this.loader.hide();
        })
        .catch(error => {
          console.error(error);
          this.loader.hide();
          this.$notify({
            message: "Failed to add all countries in answers list",
            timeout: 2000,
            type: 'warning'
          });
        });
    },
    addNewItem(listItems, initObject) {
      listItems.push(initObject);
    },
    removeItem(listItems, index) {
      listItems.splice(index, 1);
    },
    vueDate() {
      this.formData.start_date = (this.formData.start_date) ? this.formData.start_date.replace(' ', 'T') : this.formData.start_date;
      this.formData.end_date = (this.formData.end_date) ? this.formData.end_date.replace(' ', 'T') : this.formData.end_date;
    },
    laravelDate() {
      this.formData.start_date = (this.formData.start_date) ? this.formData.start_date.replace('T', ' ') : this.formData.start_date;
      this.formData.end_date = (this.formData.end_date) ? this.formData.end_date.replace('T', ' ') : this.formData.end_date;
    },
    generateSomeFields() {
      if (!this.editMode){
        let newUrl = '';
        if (this.formData.training_id && this.formData.start_date) {
          let training = this.builderData.trainingList.find(e => {return e.id === this.formData.training_id})
          const parsedDate = new Date(this.formData.start_date);
          const year = parsedDate.getFullYear();
          const month = ("0" + (parsedDate.getMonth() + 1)).slice(-2);
          newUrl = training.url + '-' + year + '-' + month;
        }
        this.formData.url = newUrl;
      }
    },

  }
}
</script>

<style>
</style>
